.nav-link-group span {
  padding-right: 1.5rem !important;
}
.nav-link a {
  color: black;
  text-decoration-line: none;
}

.sidebar-active {
  transition: opacity 0.5s;
  opacity: 1;
}
.sidebar {
  transition: opacity 0.5s;
  z-index: 2000;
  padding: 16px 30px 45px 30px;
  position: fixed;
  top: 0;
  min-height: 100%;
  width: 250px;
  background: var(--pink-color);
  display: none;
  opacity: 0;
}
.sidebar-menu {
  min-height: 100%;
  padding: 0 0 52px 0;
  margin: 0;
}
.exit-icon {
  padding-bottom: 64px;
}

@media (max-height: 640px) {
  .exit-icon {
    padding-bottom: 20px;
  }
  .sidebar-menu {
    height: 50vh;
    padding: 0 0 0 0px;
  }
}

.sidebar-menu div:nth-child(2) {
  padding-top: 30px;
  border-top: 5px white solid;
}
.sidebar-menu li {
  list-style-type: none;
  margin-bottom: 22px;
  font-size: 18px;
  font-weight: bold;
  color: white;
}
.sidebar-toggler {
  padding: 24px 24px 0 24px;
}
.sidebar-toggler img {
  z-index: 1800;
}
.sidebar-toggler h1 {
  margin-top: 8px;
  font-size: 1.5rem;
  position: relative;
  right: 10px;
}
.fade-enter {
  display: flex;
  opacity: 0;
}
.fade-enter-active {
  display: flex;

  opacity: 1;
}
.fade-enter-done {
  display: flex;
  opacity: 1;
}
.fade-exit {
  display: flex;
  opacity: 1;
}
.fade-exit-active {
  display: flex;
  opacity: 0;
}
.fade-exit-done {
  display: none;
  opacity: 0;
}

.backdrop {
  z-index: 1900;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background: rgba(255, 255, 255, 0.47);
}
