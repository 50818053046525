/* To config bootraps */
.custom-control {
  padding-left: 0;

  width: 100%;
  box-sizing: border-box;
}
.custom-switch {
  padding-left: 0;
}
.btn-pink,
.btn-darkpink {
  font-weight: 700;
  font-size: 1.25rem;
  padding: 13px 19px 13px 19px;
}
.form-control {
  background: #ececec;
  border-radius: 15px;
  border-color: transparent;
  padding: 1.25rem;
}
.form-control:focus {
  background: #ececec;
  box-shadow: 0 0 0;
  border: transparent;
}
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-radius: 4px;
}
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:not(:first-child) {
  border-radius: 4px;
}
#input-error {
  color: rgb(255, 65, 65);
  font-weight: lighter;
}

select {
  margin: 5px 0px;
  width: 100%;
  max-width: 90vw;
  background-color: rgb(240, 240, 240);
  color: #000000;
  padding: 13px 9px 13px 9px;
  border: None;
  border-radius: 5px;
}

.default-link,
.default-link:hover {
  text-decoration-line: none;
  color: black;
}
