.carousel-caption h1,
h3,
p {
  color: black;
}
.carousel-indicators li {
  background-color: black;
}
.login-header {
  margin-bottom: 30px;
  font-weight: bold;
  font-size: 2rem;
}
.toggled {
  background-color: var(--pink-color) !important;
  outline: 0;
  color: white;
  box-shadow: 0 0 0;
}
