@import "./core/components/ui/footer/footer.css";
@import "./core/components/ui/login/login.css";
@import "./core/components/ui/navbar/navbar.css";
@import "./core/components/ui/navbar/staff-sidebar.css";
@import "./core/components/pages/landing.css";
@import "./core/components/pages/staff-pages/disable-court/disable-court.css";
@import "./core/components/ui/loading/loading.css";
:root {
  --lightpink-color: #ffeced;
  --pink-color: #ff80ab;
  --medium-pink-color: #ffb2dd;
  --darkpink-color: #c94f7c;
  --grey-color: #e6e6e6;
  --red-color: #c9302c;
  --bg-color: #fafafa;
  --lightgrey-color: #eeeeee;
  --text-color: #373a3c;
  --sm-width: 576px;
  --md-width: 768px;
  --lg-width: 992px;
  --xlg-width: 1200px;
}

body,
html,
#root {
  width: 100%;
  height: 100%;
  overflow: auto;
}

* {
  font-weight: 700;
}

#root {
  background: var(--bg-color);
}

.button-group button {
  max-width: auto;
  max-height: 52px;
  width: 100%;
  box-sizing: border-box;
  border: transparent;
  border-radius: 15px;
  margin-bottom: 20px;
  cursor: pointer;
}

.toggle-button {
  max-width: auto;
  max-height: 52px;
  width: 100%;
  box-sizing: border-box;
  border: transparent;
  border-radius: 15px;
  margin-bottom: 20px;
}

.btn-mediumPink {
  background-color: var(--medium-pink-color);
  color: white;
}

.btn-pink {
  background-color: var(--pink-color);
  color: white;
  border-radius: 15px;
}

.btn-toggle {
  font-size: 16px;
  margin-right: 10px;
  background-color: var(--lightgrey-color);
  border-radius: 4px;
}

.btn-toggle.focus {
  background-color: var(--pink-color);
  outline: 0;
  color: white;
  box-shadow: 0 0 0;
}

.btn-darkpink {
  background: var(--darkpink-color);
  color: white;
}

.btn-pink:focus,
.btn-darkpink:focus,
.btn-mediumPink:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 128, 171, 0.25);
}

.btn-normal {
  font-size: medium;
  font-weight: normal;
  border-radius: 15px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.btn-outline-transparent {
  background-color: transparent;
  border: 1px solid black;
  color: black;
}

.btn-outline-transparent:hover,
.btn-outline-transparent:focus {
  background-color: var(--pink-color);
  border-color: transparent;
  color: white;
  transition: 0.3s;
  box-shadow: 0 0 0 0;
  outline: 0;
}

.btn-normal:hover {
  background-color: #ff2e7e;
  color: white;
}

.btn-outline-black:hover {
  background-color: grey;
  border-color: transparent;
  color: white;
}

.btn-outline-pink {
  color: var(--pink-color);
  border-color: var(--pink-color);
  border-radius: 15px;
}

.btn-outline-pink:hover {
  background-color: var(--pink-color);
  color: white;
  border-color: white;
}

.btn-outline-pink:focus {
  background-color: var(--pink-color);
  color: white;
  border-color: white;
  box-shadow: 0 0 0 0.2rem rgba(255, 128, 171, 0.25);
}

.btn-outline-red:hover {
  background-color: var(--red-color);
  color: white;
  border-color: white;
}

.btn-outline-green:hover {
  background-color: green;
  color: white;
  border-color: white;
}

label {
  font-weight: 400;
}

.default-mobile-wrapper {
  padding: 20px 20px 20px 20px;
  background: white;
  box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
}

.dropdown-item {
  white-space: normal;
}

.fit-button {
  white-space: normal;
}

@media (min-width: 769px) {
  .default-wrapper {
    padding: 70px 50px 70px 50px;
    background: white;
    box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
  }
}

.page-wrap {
  padding-bottom: 80px;
  min-height: 500px;
}

@media (max-width: 768px) {
  .page-wrap {
    margin-top: 30px;
  }
}

@media (min-width: 768px) {
  .page-wrap {
    margin-top: 3%;
    margin-bottom: 12%;
  }
}

@media (min-width: 992px) {
  .page-wrap {
    margin-top: 6%;
    margin-bottom: 12%;
  }
}

.logo {
  margin-left: 9vw;
  max-width: 81px;
  max-height: 54px;
}

.styled-link {
  text-decoration-line: none;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.3s;
}

.styled-link:hover {
  text-decoration-line: none;
  color: black;
  font-weight: bold;
  font-size: 18px;
}

.styled-link-black-button {
  text-decoration-line: none;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.3s;
}

.styled-link:hover {
  text-decoration-line: none;
  color: var(--darkpink-color);
  font-weight: bold;
  font-size: 18px;
}

.table td {
  font-weight: lighter;
  vertical-align: middle;
}

.tr-normal {
  background-color: white;
}

.tr-pink {
  background-color: rgb(252, 177, 223);
}

p {
  font-weight: lighter;
}

p.link {
  cursor: pointer;
  font-weight: lighter;
  text-decoration: underline;
  width: 30%;
}

.page-link {
  font-weight: lighter;
  background: var(--pink-color);
  color: white;
  border-color: white;
}

.page-link:hover {
  background: #df609f;
  color: white;
}

.page-link:focus {
  background: #df609f;
  box-shadow: 0 0 0 0.2rem rgba(255, 128, 171, 0.25);
}

.page-item.active .page-link {
  border-color: white;
  background: #b84f83;
}

.form-control {
  height: 3rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.card {
  border-radius: 15px;
}

.card .dim-white {
  border: transparent;
  background-color: whitesmoke;
}

.form-label {
  font-weight: bold;
}

.floating-label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 125px;
  height: 50px;
  position: absolute;
  z-index: 5;
  transition: 0.3s;
}

.custom-checkbox {
  margin-top: 4px;
  width: 15px;
  height: 15px;
}

.custom-select {
  background-color: #ffadc8;
}

.time-checkbox {
  width: 15px;
  height: 15px;
}

.input-error {
  color: red;
}

.link {
  width: 100%;
  border-color: transparent;
  position: relative;
  z-index: 1;
}

.linkhead {
  font-size: 20px;
  margin-bottom: 10px;
  line-height: 19px;
  font-weight: 400;
  margin-top: 5px;
}

.linkbody {
  font-size: 14px;
  font-weight: 200;
  line-height: 17px;
}

.homepage-icon {
  float: right;
  border-left: 1px solid;
}

.darkgrey {
  color: darkgrey;
}

.homepage-warning-head {
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  color: red;
  margin: 0px 10px 5px 10px;
}

.homepage-warning-body {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: red;
  margin: 0px 10px 20px 10px;
}

.higher {
  margin-bottom: 300px;
}

.modal {
  z-index: 2100;
}

.time-slot-container {
  max-height: 400px;
  overflow-y: scroll;
}

.time-slot-container-hidden {
  height: 0px;
}

.time-container {
  width: 100% !important;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
  width: 100%;
  margin: 0;
}

.select-drop-down {
  border-radius: 15px;
  background-color: #ff80ab;
  color: white;
  transition: 0.3s;
}

.select-drop-down:hover {
  border-radius: 2px;
  border-color: #c94f7c;
  transition: 0.3s;
}

.select-drop-down option {
  background-color: #ffffff !important;
  border-radius: 10px;
  color: black;
}

.drop-down-item:hover {
  background-color: #ffffff;
  color: #c94f7c;
  transition: 1s;
}

.glass {
  min-width: 100%;
  height: 160px;
  position: relative;
  align-self: center;
  align-content: center;
  background: linear-gradient(186.99deg, #ffe4ed -11.75%, rgba(255, 255, 255, 0) 157.69%), rgba(231, 231, 231, 0.28);
  border: 2px solid rgba(244, 244, 244, 0.41);
  box-sizing: border-box;
  border-radius: 15px;
}

.glass img {
  width: 100%;
  height: 200px;
}

.glass-contents {
  position: absolute;
  top: 15px;
  left: 15px;
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(0);
  }
}

.animated-card {
  animation: 0.4s ease 0s 1 slideInFromBottom;
}

.blob-container {
  width: 100%;
  position: relative;
  align-content: center;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.blob-container img {
  height: 200px;
  position: fixed;
  bottom: 0px;
  align-self: center;
  flex-shrink: 0;
}

.box-container {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 10px 30px 5px rgba(204, 204, 204, 0.4);
  margin-bottom: 20px;
  text-align: start;
  padding: 20px;
}

.box-container-alt {
  background-color: var(--pink-color);
  border-radius: 15px;
  box-shadow: 0px 10px 30px 5px rgba(204, 204, 204, 0.4);
  margin-bottom: 20px;
  text-align: start;
  padding: 20px;
  width: 100%;
}

.box-container-alt p {
  color: white;
}

.modal {
  display: block;
}

.modal-content {
  display: block !important;
  border-color: transparent !important;
  border-radius: 15px !important;
}

.modal-footer,
.modal-header {
  border-color: transparent;
}

.btn-pink-pink {
  background-color: var(--pink-color);
  color: white;
}

.btn-lightpink-lightpink {
  background: rgba(255, 178, 221, 1);
  color: black;
}

td {
  font-size: 16px;
  font-weight: 400;
  line-height: 34px;
}

.grey {
  color: grey;
}

.cancel-btn {
  width: 100%;
  height: 50px;
  font-size: 22px;
  text-align: center;
  background-color: transparent;
  color: red;
  border-radius: 15px;
  border-color: red;
}

.return-btn {
  border-radius: 15px;
  height: 50px;
  font-size: 22px;
  text-align: center;
  background-color: transparent;
}

.fixed-bottom {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 50px;
}

.team-icon-container .icon {
  margin: 0 auto;
  text-align: center !important;
}

.icon {
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.vertical-line {
  border-left: 1px solid #b1b1b1;
  width: 1px;
  height: calc(100% - 54px);
  margin-top: 5px;
  margin-left: 50%;
  margin-bottom: 54px;
}

.vertical-line::after {
  display: none;
}

.description-container {
  position: relative;
}

.description-container img {
  max-height: 35px;
}

.description-container h3 {
  position: absolute;
  top: 0;
}

.description-paragraph {
  margin-top: 20px;
}

.cancel-btn {
  width: 100%;
  height: 50px;
  font-size: 22px;
  text-align: center;
  background-color: transparent;
  color: red;
  border-radius: 15px;
  border-color: red;
  margin-bottom: 30px;
}

.time-picker {
  height: 30px;
}

.form-pink {
  background-color: rgb(255, 194, 232);
  border-radius: 5px;
}

.form-pink:focus {
  background-color: rgb(255, 194, 232);
  border-radius: 5px;
}

.square-input {
  width: 100px;
  height: 60px;
}

.join-waitingroom-card {
  margin-bottom: 200px;
}

.footer-decoration {
  z-index: -10;
}

.sport-card-input {
  margin: 10px;
  padding: 00px;
}

.sport-radio input {
  visibility: hidden;
  display: none;
}

.sport-radio p {
  margin: 0;
}

.sport-radio .box-container,
.box-container-alt {
  width: 100%;
}

.hint-card-background {
  padding: 25px;
  width: 100%;
  display: block;
  border-radius: 10px;
  background: #efefef;
}

.unselectable {
  user-select: none;
}

.form-file-input {
  cursor: pointer;
}

.spinner {
  position: absolute;
  right: 30px;
}

.status-error {
  color: red;
}
