.landing.container {
  height: 100vh;
}

.landing .small.textgroup {
  position: static;
  z-index: 5;
}

.landing.imagegroup {
  height: 100vh;
}

.landing .image {
  position: relative;
  z-index: 0;
  max-width: 500px;
}

.landing.textgroup h4 {
  font-size: 4vw;
}

.landing.textgroup h6 {
  font-size: 1.8vw;
}

.landing.textgroup {
  position: static;
  z-index: 1;
  margin-top: 20vh !important;
}

.landing .group {
  position: relative;
  max-height: 100vh;
  max-width: 500px;
  left: 00px;
  z-index: 0;
}

.landing .small .img {
  max-width: 110vw;
  margin-top: -140px;
}

.landing.relative-img {
  position: fixed;
  max-width: 350px;
  right: 0;
  top: 15vh;
}

.landing.small-relative-img {
  position: relative;
  max-width: 100vw;
  max-height: 100vw;
}

.landing.small-imagegroup {
  max-height: 60vh !important;
}

@media (min-width: 769px) {
  .landing.relative-img {
    max-width: 450px;
  }
}

@media (min-width: 1023px) {
  .landing.textgroup {
    margin-top: 20vh !important;
  }
}

@media (min-width: 1200px) {
  .landing.relative-img {
    max-width: 550px;
    top: 25vh;
  }
  .landing.textgroup {
    margin-top: auto !important;
  }
}

@media (min-width: 1400px) {
  .landing.relative-img {
    max-width: 600px;
    top: 18vh;
  }
}

@media (min-width: 2500px) {
  .landing.relative-img {
    max-width: 800px;
    right: 10vw;
    top: 10vh;
  }
}
