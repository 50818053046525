.disable-court-wrapper {
  background-color: transparent;
}
.disable-court-filter select,
.disable-court-filter input {
  background-color: var(--medium-pink-color);
  height: 2.5rem;
  height: 50px;
  border-radius: 5px;
  margin-right: 5px !important;
  padding: 0.5rem;
  max-width: 120px;
  transition: 0.2s;
}
.disable-court-filter select option {
  background-color: var(--medium-pink-color);
}
.disable-court-table thead {
  border: 0;
  background-color: var(--medium-pink-color);
}
.disable-court-table tbody > tr {
  border: 0;
  background-color: white;
}
.disable-court-table tbody > tr {
  border-bottom: 2px solid #eceeef;
}
.disable-court-link,
.disable-court-link:hover {
  text-decoration-line: none;
  color: white;
}
.disable-court-button {
  font-size: 1.2rem;
  padding: 0.375rem 0.75rem;
  min-width: 120px;
  border-radius: 15px;
  color: white !important;
}

.list-data .col h5 {
  font-weight: 700;
  font-size: 1.1rem;
}

.disable-court-form select,
.disable-court-form input {
  border-radius: 4px;
}
.small-table {
  max-height: 350px;
  overflow: auto;
}

.wide-modal > div {
  max-width: 850px;
}
